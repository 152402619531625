window.getAdj = getAdj;
function getAdj() {
  let adj = require('/js/json/adj.json');
  return adj;
}

window.getNoun = getNoun;
function getNoun() {
  let noun = require('/js/json/noun.json');
  return noun;
}

window.passwordGenerator = passwordGenerator;
function passwordGenerator() {
  let adj = getAdj();
  console.log(adj);
  let adjArray = adj.adjs;
  let randomAdj = adjArray[Math.floor(Math.random() * adjArray.length)].toString();
  let capAdj = randomAdj.charAt(0).toUpperCase() + randomAdj.slice(1);
  let noun = getNoun();
  let nounArray = noun.nouns;
  let randomNoun = nounArray[Math.floor(Math.random() * nounArray.length)].toString();
  let capNoun = randomNoun.charAt(0).toUpperCase() + randomNoun.slice(1);
  let number = Math.floor(Math.random() * 1000 + 1);

  let password = capAdj + capNoun + number;
  return password;
}
